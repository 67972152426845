import React from "react"
import { H2, Row } from "./UI"

export default props => (
  <Row className="justify-center">
    <div className="mx-auto max-w-screen-sm text-center">
      <H2>{props.title}</H2>
      <p className="text-sm md:text-base">{props.text}</p>
    </div>
  </Row>
)
