import React from "react"
import { H4 } from "./UI"

export const RegularCard = props => (
  <div className="shadow-box h-full flex flex-col bg-white">
    <img src={props.image} alt={props.title}></img>
    <div className="px-3 py-4 flex flex-col flex-auto text-center">
      <H4 className="mb-2">{props.title}</H4>
      <p className="text-cardGray">{props.text}</p>
    </div>
  </div>
)
