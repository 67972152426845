import React from "react"
import Layout from "../components/Layout"
import { H1, ButtonPurp } from "../components/UI"
import SectionTitle from "../components/SectionTitle"
import { RegularCard } from "../components/Cards"

export default () => (
  <Layout>
    <section>
      <div
        className="mx-auto h-auto bg-center relative"
        style={{
          backgroundImage:
            "linear-gradient(110deg,rgba(11,123,218,.6) 0,rgba(255,0,200,.6) 100%), url('https://kevinsmithgroup.com/wp-content/uploads/2018/10/home-page-header-hero.jpg')",
        }}
      >
        <div className="text-center pt-16 px-3 max-w-screen-md mx-auto">
          <H1>Kevin Smith Transportation Group Limo Service</H1>
          <p className="text-white text-sm md:text-xl mb-5">
            Weddings | Airports | Nights Out | Prom | Corporate | Cruise Ports |
            Bachelor/Bachelorette Parties, Limo Service & More!
          </p>
          <ButtonPurp linkTo="/contact">Request a Quote</ButtonPurp>
        </div>
        <div
          className="h-24 bottom-0 block w-full"
          style={{
            backgroundImage:
              "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik02NDAgMTM5TDAgMHYxNDBoMTI4MFYwTDY0MCAxMzl6Ii8+PC9nPjwvc3ZnPg==)",
            backgroundSize: "100% 96px",
          }}
        ></div>
      </div>
    </section>
    <section className="py-8">
      <SectionTitle
        title="Services"
        text="No matter what the occasion, KSTG can provide you with the right vehicle and logistical planning to go along with it"
      />
      <div className="mx-auto max-w-screen-lg px-3 py-8 flex flex-wrap">
        <div className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-5">
          <RegularCard
            title="Wedding Limo Service"
            text="Let KSTG assign you your own Wedding Coordinator who will arrange all of your wedding limo services. From the party bus rental for your bridal party to round trip shuttle service for all of your wedding guests. Luxury is spelled KSTG."
            linkTo="/wedding-transportation-service"
            image="https://kevinsmithgroup.com/wp-content/uploads/2018/07/wedding-transportation-1.jpg"
          />
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-5">
          <RegularCard
            title="Airport Limo Service"
            text="With KSTG’s airport limo service, the only thing that will be on your mind will be your vacation. We offer luxury airport limo service to all major airports in the Philadelphia area including PHL, JFK, EWR, BWI, IAD and more."
            linkTo="/airport-transportation-service"
            image="https://kevinsmithgroup.com/wp-content/uploads/2018/07/airport-transportation-1.jpg"
          />
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-5">
          <RegularCard
            title="Corporate Transportation"
            text="KSTG is GDS integrated. Our corporate transportation solution includes daily shuttle services, executive transfers, and airport transportation. Call KSTG today and be assigned a dedicated Concierge for all of your travel needs."
            linkTo="/corporate-transportation-service"
            image="https://kevinsmithgroup.com/wp-content/uploads/2018/07/corporate-travel.jpg"
          />
        </div>
      </div>
    </section>
  </Layout>
)
